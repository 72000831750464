import { axios } from "./axios";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import store from "../store";
import FileUtils from "@/common/utils/fileUtils";

@Module({
    dynamic: true,
    namespaced: true,
    store,
    name: "products",
})
export class ProductsModule extends VuexModule {
    @Action
    getAll() {
        return axios.get<Product[]>("/products");
    }

    @Action
    getAllActive() {
        return axios.get<Product[]>("/products/active");
    }

    @Action
    save({ product }: { product: Product }) {
        const formData = FileUtils.objectToFormData(product);
        if (!!product.id && product.id > -1) {
            return axios.put("/products", formData);
        } else {
            return axios.post("/products", formData);
        }
    }

    @Action
    delete({ productId }: { productId: number }) {
        return axios.delete(`/products/${productId}`);
    }
}

export interface Product {
    id: number;
    name: string;
    description: string;
    display: boolean;
    additionalInfo: string;
    infoLength: string;
    mediaUri: string;
    imageUpload: Blob;
    productVariants: ProductVariant[];
}

export interface ProductVariant {
    id: number;
    size: string;
    quantityAvailable: number;
    cost: number;
    additionalInformationCost: number;
}
