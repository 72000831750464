import { axios } from "./axios";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import store from "../store";
import { Product } from "./products-module";

@Module({
    dynamic: true,
    namespaced: true,
    store,
    name: "payments",
})
export class PaymentsModule extends VuexModule {
    @Action
    shop({ basket }: { basket: Product[] }) {
        const items = basket.map((item) => ({
            productId: item.id,
            productVariants: item.productVariants.map((pv) => ({ id: pv.id })),
        }));
        return axios.post("/payments/shop", { items });
    }

    @Action
    subs({ members, payment }: { members: Member[]; payment: number }) {
        return axios.post("/payments/subs", { members, payment });
    }

    @Action
    camp({ camp, payment }: { camp: Camp; payment: number }) {
        return axios.post("/payments/camp", { camp, payment });
    }
}

export interface Member {
    firstName: string;
    lastName: string;
    teams: number[];
    period: number;
}

export interface Camp {
    firstName: string;
    lastName: string;
    member: boolean;
    session: string;
    tshirt: string;
    dateOfBirth: Date;
    gender: string;
    medical: string;
    information: string;
    address: string;
    contactName1: string;
    contactPhone1: string;
    contactEmail1: string;
    contactName2: string;
    contactPhone2: string;
    agreeTerms: boolean;
}
