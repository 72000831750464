












































































































































































import { Component, Vue, Model, Emit, Watch } from "vue-property-decorator";
import { Camp } from "@/store/payments-module";
import { CampsApi } from "@/store/camps-api";
import CForm from "@/common/components/CForm.vue";
import { required, maxLength, email } from "@/common/validators/rules";
import CDatePicker from "@/common/components/CDatePicker.vue";

@Component({ components: { CForm, CDatePicker } })
export default class CampMember extends Vue {
    @Model("change") camp!: Camp;

    campsApi = new CampsApi();
    selectedSession: any = {};

    validators = { required, maxLength, email };
    campSessions: any[] = [];
    tshirts = ["Age 8 yrs", "Age 11 yrs", "Small Adult", "Medium Adult", "Large Adult"];

    get maxYear() {
        return (new Date().getFullYear() - 4).toString();
    }

    created() {
        this.campsApi.getAll().then((res) => (this.campSessions = res.data));
    }

    @Emit()
    remove() {
        return;
    }

    @Watch("selectedSession")
    sessionSelected(session: any) {
        this.camp.session = session.name;

        this.$emit("session", session);
    }
}
