


















import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ProductsModule, Product } from "@/store/products-module";
import CForm from "@/common/components/CForm.vue";
import { loadStripe, Stripe, StripeCardElement } from "@stripe/stripe-js";

@Component({ components: { CForm } })
export default class CPayDialog extends Vue {
    productsStore = getModule(ProductsModule);
    isMounted = false;
    products: Product[] = [];
    basket: Product[] = [];
    selected = {};
    stripeInstance!: Stripe | null;
    payDialog = false;
    stripeKey!: string;
    card!: StripeCardElement;
    busy = false;
    error: string | undefined = "";
    success: string | undefined = "";

    get disablePayButton() {
        return !!this.error || !!this.success || this.busy;
    }

    open(stripeKey: string) {
        this.stripeKey = stripeKey;
        this.payDialog = true;

        loadStripe(process.env.VUE_APP_STRIPE).then((res) => {
            if (!res) {
                return;
            }

            this.stripeInstance = res;

            var elements = this.stripeInstance.elements();
            var style = {
                base: {
                    color: "#32325d",
                    fontFamily: "Arial, sans-serif",
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                        color: "#32325d",
                    },
                },
                invalid: {
                    fontFamily: "Arial, sans-serif",
                    color: "#fa755a",
                    iconColor: "#fa755a",
                },
            };

            this.card = elements.create("card", { style: style });
            // Stripe injects an iframe into the DOM
            this.card.mount("#card-element");
            //this.$nextTick(() => this.card.focus())
            this.card.on("change", () => (this.error = ""));
            this.busy = false;
            this.success = this.error = "";
        });
    }

    payWithCard() {
        this.busy = true;
        this.stripeInstance
            ?.confirmCardPayment(this.stripeKey, {
                payment_method: {
                    card: this.card,
                },
            })
            .then((result) => {
                this.busy = false;
                if (result.error) {
                    // Show error to your customer
                    this.error = result.error.message;
                } else {
                    // The payment succeeded!
                    this.success = result.paymentIntent?.id;
                    this.$emit("success");
                }
            });
    }

    close() {
        this.payDialog = false;
    }
}
