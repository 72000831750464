








































































import { Component, Vue, Ref } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { PaymentsModule, Camp } from "@/store/payments-module";
import CForm from "@/common/components/CForm.vue";
import CPayDialog from "@/common/components/CPayDialog.vue";
import SubMember from "./CampMember2.vue";

@Component({ components: { CForm, CPayDialog, SubMember } })
export default class CampForm2 extends Vue {
    @Ref() readonly form!: any;
    @Ref() readonly payDialog!: CPayDialog;

    paymentsStore = getModule(PaymentsModule);
    isMounted = false;
    camp: Camp = { member: true } as Camp;

    get payment() {
        // return this.camp.member ? 60 : 70;
        return this.camp.member ? 80 : 80;
    }
    set payment(value) {
        return;
    }

    clear() {
        this.form.reset();
    }

    pay() {
        if (!this.form.validate()) {
            return;
        }

        this.paymentsStore
            .camp({ camp: this.camp, payment: this.payment })
            .then((res) => this.payDialog.open(res.data.clientSecret));
    }
}
