










































































































































































import { Component, Vue, Model, Emit } from "vue-property-decorator";
import { Camp } from "@/store/payments-module";
import CForm from "@/common/components/CForm.vue";
import { required, maxLength, email } from "@/common/validators/rules";
import CDatePicker from "@/common/components/CDatePicker.vue";

@Component({ components: { CForm, CDatePicker } })
export default class CampMember extends Vue {
    @Model("change") camp!: Camp;

    validators = { required, maxLength, email };
    sessions = [
        {
            text: "U13/14 Girls - Tues 10AM - 11:30AM - June 6, 13, 20, 27, July 4",
            value: "U13/14 Girls - Tues 10AM - 11:30AM - June 6, 13, 20, 27, July 4",
            disabled: true,
        },
        {
            text: "U13/14 Boys - Tues 11:30AM - 1PM - June 6, 13, 20, 27, July 4",
            value: "U13/14 Boys - Tues 11:30AM - 1PM - June 6, 13, 20, 27, July 4",
            disabled: true,
        },
        {
            text: "U15/16 Girls - Wed 10AM - 11:30AM - June 7, 14, 21, 28, July 5",
            value: "U15/16 Girls - Wed 10AM - 11:30AM - June 7, 14, 21, 28, July 5",
            disabled: true,
        },
        {
            text: "U15/16 Boys - Wed 11:30AM - 1PM - June 7, 14, 21, 28, July 5",
            value: "U15/16 Boys - Wed 11:30AM - 1PM - June 7, 14, 21, 28, July 5",
            disabled: true,
        },
        {
            text: "U17/18 Girls - Thur 10AM - 11:30AM - June 8, 15, 22, 29, July 6",
            value: "U17/18 Girls - Thur 10AM - 11:30AM - June 8, 15, 22, 29, July 6",
            disabled: true,
        },
        {
            text: "U17/18 Boys - Thur 11:30AM - 1PM - June 8, 15, 22, 29, July 6",
            value: "U17/18 Boys - Thur 11:30AM - 11PM - June 8, 15, 22, 29, July 6",
            disabled: true,
        },
    ];
    tshirts = [
        // 'Age 5-6',
        // 'Age 7-8',
        // 'Age 9-11',
        // 'Age 12-13',
        "Extra Small Adult",
        "Small Adult",
        "Medium Adult",
        "Large Adult",
        "Extra Large Adult",
    ];

    get maxYear() {
        return (new Date().getFullYear() - 4).toString();
    }

    @Emit()
    remove() {
        return;
    }
}
